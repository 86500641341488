export default {
  /**
   * AUTH
   */
  SET_AUTH_OUTDOOR(state, authOutdoor) {
    state.authOutdoor = authOutdoor;
  },

  /**
   * USER
   */
  SET_USER_OUTDOOR(state, userOutdoor) {
    state.userOutdoor = userOutdoor;
  },

  /**
   * BOX
   */
  SET_BOX_OUTDOOR(state, boxOutdoor) {
    state.boxOutdoor = boxOutdoor;
  },

  /**
   * GLOBAL LOADING
   */
  SET_GLOBAL_LOADING_OUTDOOR(state, globalLoadingOutdoor) {
    state.globalLoadingOutdoor = globalLoadingOutdoor;
  },

  /**
  * TRANSLATIONS
  */
  SET_I18N_OUTDOOR(state, i18nDataOutdoor) {
    state.i18nDataOutdoor = i18nDataOutdoor;
  },
};
